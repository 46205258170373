.Footer-main{
  min-width:100%;
  background-color: black; 
  overflow-x: hidden;
  border-bottom: transparent;
  
}
.Footer-header{
  color:white;
  padding-left:1rem;
}


@media screen and (max-width:350px) {
  .Footer-main{
    font-size:0.8rem;
  }
}