.Header-image-div{
  display:flex;
  justify-content: center;
  align-items: center;
  color:black;
  width:95%;
}

.Header-image-div-container{
  background-image: url('../media/pictures/oyster-platter.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:100vh;
  width:90vw;
  padding:0 1em;
  overflow: hidden;
  display: flex;
  color:White;
  font-weight: 700;
}

.Header-image-div-text{
  max-height: fit-content;
  color:white;
  font-size:2rem;
  text-shadow: 2px 2px black;
  font-weight: 700;
}

.Header-image-div-container::after{
  opacity:0.7
}

@media screen and (max-width:515px){
  .Header-image-div-text{
    font-size: 1.6rem;
  }
}
@media screen and (max-width:330px){
  .Header-image-div-container{
    width:89%;
  }
  .Header-image-div-text{
      font-size:1.4rem;
  }
}