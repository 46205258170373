.Nav-main{
  background-color: rgb(248, 245, 240);
  position:fixed; 
  width:100%;
  height:5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 2.5rem;
  z-index:2;
}

.Nav-name{
  margin-right:5%;
}

.Nav-icon{
  margin-right:1rem;
  height:2.3rem;
  width:2.3rem;
  margin-top:0.7rem;
}

.Nav-icon:hover{
  cursor:pointer;
}

.Nav-list-parent_web{
  list-style-type: none;
  display:flex;
  flex-direction: row;
  height:100%;
}

.Nav-list-item{
  padding-right:2.5rem;
  font-size:1.2rem;
  margin-top:1rem;
  font-weight: bold;
  color:rgb(80, 77, 77);
}

.Nav-list-item:hover{
  cursor: pointer;
  text-decoration: underline;
}

.Nav-list-parent_mobile{
  list-style-type: none;
  display:flex;
  flex-direction: row;
  height:100%;
}

/* Side Menu */

.SideMenu {
  position: fixed;
  right: -100%;
  top: 0;
  height: 100vh;
  background: black;
  width: 50%;
  margin-right: 0rem;
  transition: 1s;
  z-index: 2;
}

.show{
  transform-origin: 100% 60%;
  transform: translateX(-200%);
  transition: 0.5s;
  overflow-y: hidden;
}

.hide{
  z-index:2;
  transform-origin: 100% 60%;
  transform: translateX(200%);
  transition: 0.5s;
}

.SideMenu{
  display:flex;
  flex-direction: column;
}

.SideMenu-close{
  font-size: 2rem;
  text-align:right;
  margin-right:1rem;
}

.SideMenu-close-button{
  cursor:pointer;
  color:white;
}

.SideMenu-list{
  list-style-type: none;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  padding:0;

}

.SideMenu-item{
  background-color: black;
  color:white;
  margin-left:0;
  margin-bottom:1rem;
  margin-top: 1rem;
  font-size:1.6rem;
  text-align: center;
  font-weight:bold;
}

.SideMenu-item:hover{
  cursor: pointer;
  background-color: gray;
}



@media screen and (max-width:330px){
  .Nav-icon{
    height:2rem;
    width:2rem;
    margin-left:-0.6rem;
  }
}