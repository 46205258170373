.Gallery-main{
  width:100%;
  justify-items: center;
  margin-bottom:2rem;
  height:30%;
}

.Gallery-title{
  font-size:1.7rem;
  font-weight:bold;
  margin-left:1%;
  margin-top:-1rem;
}

.Gallery-image-thumbnail{
  width:80%
}

.Gallery-image-thumbnail img{
  width:80%;
}

.Gallery-button-div{
  display:flex;
  flex-direction:row;
  justify-content: space-around;
  margin-bottom:3rem;
  margin-top:3rem;
}

.Gallery-button{
  height:2%;
  width:2%;
}

.Gallery-button-flipped{
  height:2%;
  width:2%;
  transform:rotate(180deg);
}

.Gallery-button:hover{
  cursor:pointer;
}
.Gallery-button-flipped:hover{
  cursor:pointer;
}

@media screen and (min-width:1000px){
  .slick-slider img{
    max-height:30rem;
  }
  .Gallery-image-thumbnail{
    width:65%;
  }

  .Gallery-image-thumbnail img{
    width:65%;
    margin-bottom:0;
  }
  .Gallery-button{
    height:1.4%;
    width:1.4%;
  }

  .Gallery-button-flipped{
    height:1.4%;
    width:1.4%;
    transform:rotate(180deg);
  }

}

@media screen and (max-width:750px){
 .slick-slide {
  height:65vh;
  width:90%;
}

.slick-slide img {
  height:60vh;
  width:80%;
  } 
.Gallery-button{
  height:1.5rem;
  width:1.5rem; 
  }
.Gallery-button-flipped{
  height:1.5rem;
  width:1.5rem;
  }
}


@media screen and (max-width:450px){
  
  .slick-slide img {
   height:45vh;
   width:90%;
  } 
  
  .Gallery-button{
    height:1.3rem;
    width:1.3rem; 
  }
  .Gallery-button-flipped{
    height:1.3rem;
    width:1.3rem;
  }
  .Gallery-title{
    font-size: 1.6rem;
    text-align: left;
  }
}

@media screen and (max-width:375px){
  
  .slick-slide img {
   height:45vh;
   width:90%;
  } 
  
  .Gallery-button{
    height:1.3rem;
    width:1.3rem; 
  }
  .Gallery-button-flipped{
    height:1.3rem;
    width:1.3rem;
  }
  .Gallery-title{
    font-size: 1.4rem;
    text-align: left;
  }
}