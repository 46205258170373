.Copyright-main{
  width:90%;
  background-color: black;
  color:white;
  padding-left:1rem;
  align-items: center;
  line-height: 3rem;
  border-bottom:transparent;
}


@media screen and (max-width:400px) {
  .Copyright-main{
    font-size:0.8rem;
  }
}