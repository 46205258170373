@import 'App.css';
@import 'Nav.css';
@import 'Main.css';
@import 'Header.css';
@import 'About.css';
@import 'Gallery.css';
@import 'Footer.css';
@import 'Form.css';
@import 'Contact.css';
@import 'Copyright.css';
@import 'Varieties.css';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width:100vw;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline: 0;
  outline: none;
}

#name{
  font-size: 2rem;
  font-weight:800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-open{
  background-color: black;
  opacity:0.5;
  pointer-events:none;
}

/* Div placeholders for more accurate scroll to part of page on click behavior */

#About{
  margin-bottom:5rem;
}

#Gallery{
  margin-bottom:5rem;
}

#Contact{
  margin-bottom:5rem;
}
/* Div placeholders for more accurate scroll to part of page on click behavior^ */

.required{
  color:red
}

@media screen and (max-width:515px){
  #name{
  font-size:1.8rem;
  }
}


@media screen and (max-width:330px){
    #name{
    font-size:1.5rem;
    line-height:2.8rem;
    margin-right:0.5rem;
  }
}
