.Varieties-main{
 width:100%;
 margin-bottom:2rem;
 display:flex;
 flex-direction: column;
 align-items:center;
 border-radius:10px;
}
.Varieties-grid{
  max-width:90%;
  text-align: center;
  display:grid;
  grid-template-columns: auto auto auto;
  grid-gap:10px;
  padding:1rem;
  left:0;
}

.Varieties-outer{
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  padding:2rem;
  border-radius: 10px;
}

.Varieties-arrow{
  height:2rem;
  width:7rem;
  opacity:0.7;
}

.flipped{
  transform:rotate(180deg);
}

.Varieties-arrow:hover{
  cursor:pointer;
}

.Varieties-child{
  display:flex;
  flex-direction:column;
  justify-content: start;
  border:1px solid transparent;
  height:30rem;
  border-radius:5px;
}

.Varieties-image{
  width:100%;
  max-height: 30%;
}

.Varieties-child-p{
  color:black;
  width:90%;
  text-align: center;
  align-self: center;
  line-height:1.6rem;
}

@keyframes fadeIn {
  0% 
    { 
      opacity: 0; 
    }
  100% 
    { 
    opacity: 1; 
    }
}

.Animation-change{
  animation: fadeIn 1s
}

.disable{
  pointer-events: none;
}


@media screen and (max-width:650px){
  .Varieties-main{
    max-width: 93vw;
  }
  .Varieties-grid{
    grid-template-columns: auto;
  }
  .Varieties-image{
    height:10rem;
    width:60%;
    align-self: center;
  }
  .Varieties-child{
    padding:0.5rem;
    height:fit-content;

  }
  .Varieties-arrow{
    width:8rem !important;
    height:3rem !important;
  }

}

@media screen and (max-width:450px){
  .Varieties-main{
    width: 100vw;
  }
  .Varieties-grid{
    padding:0;
    grid-template-columns: 1fr;
  }
  .Varieties-title{
    font-size:1.1rem;
    width:95%;
  }
  .Varieties-grid h3{
    font-size: 1rem;
    text-decoration: underline;
  }
  .Varieties-grid p{
    font-size: 0.8rem;
    margin-top:0.1rem;
  }
  .Varieties-arrow{
    width:1rem !important;
    height:2rem !important;
  }
  .Varieties-image{
    height:10rem;
    width:100%;
    align-self: center;
  }
  .Varieties-child{
    margin-left:0.5rem;
    margin-right:0.5rem;
  }
  .Varieties-arrow{
    height:2rem;
    width:8rem;
    opacity:0.7;
  }
}