.Body-container{
  min-height:100vh;
  width:100%;
  margin-top:8rem;
  background-color: black;
}

.App{
  display:flex;
  justify-content: center;
  background-color: transparent;
  overflow-x: hidden;
}

/* Unified Styles */ 

img {
  display: block;
  max-width: 100%;
}
.hidden{
  display:none;
  visibility:hidden;
}

.menu-overlay{
  min-height:100vh;
  width:100vw;
  background-color: black;
  opacity:0.4;
}

