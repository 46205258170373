.Form-main{
  width:95%;
  border:1px solid black;
  margin-bottom:2rem;
  padding-left:0.5%;
}

.Form-div{
  display:grid;
  grid-template-columns:1fr 1fr;
  padding:1rem;
  grid-gap:10px;
  background-color: black;
  color:white;
  width:100%;
}

.Form-nest-div{
  display:flex;
  flex-direction:column;
}

.Form-label{
  font-size:1.1rem;
  font-weight:bold;
  margin-bottom:0.5rem;
}

.Form-input{
  width:90%;
  height:1.5rem;
  font-size:1rem;
  font-weight:bold;
  padding:0.5rem;
  border-radius: 5px;
  border-color:transparent;
}

.Form-message-div{
  display:flex;
  flex-direction: column;
  width:100%;
  padding:1rem;
  color:white;
  font-weight:bold;
  font-size:1.3rem;
}

.Form-message{
  height:10rem;
  width:95%;
  padding:0.5rem;
  resize:none;
  font-size:1.1rem;
  font-weight:bold;
  line-height:1.7rem;
}

.Form-submit-div{
  display:flex;
  flex-direction: column;
  width:100%;
  padding:1rem;
  color:white;
  font-weight:bold;
  font-size:1.3rem;
}

.Form-submit{
  width:8rem;
  height:2.5rem;
  font-size:1.2rem;
  font-weight:bold;
  color:white;
  background-color:rgb(92, 64, 255);
  margin-top:0.5rem;
  border:none;
  border-radius:5px;
}

.Form-submit:hover{
  cursor:pointer;
  background-color:rgb(119, 96, 252);
}


@media screen and (max-width:500px){
  .Form-input{
    width:80%;
    height:1.2rem;
    font-size:1rem;
    font-weight:bold;
    padding:0.5rem;
  }
  .Form-message{
    width:90%;
  }
}


@media screen and (max-width:330px){
  .Form-label{
    font-size: 0.9rem;
  }
  .Form-input{
    width:70%;
    height:1rem;
    font-size:0.9rem;
    font-weight:bold;
    padding:0.4rem;
  }
  .Form-message{
    width:88%;
    font-size:0.9rem;
  }
  .Form-submit{
    width:6.5rem;
    height:1.8rem;
    font-size: 1rem;
  }
  label{
    font-size: 1rem;
  }
}