.About-paragraph-div{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  width:95vw;
  margin-top:-2rem;
  }

.About-paragraph{
  background-color:var(--color-main);
  color:var(--color-main-light);
  min-height:10vh;
  padding:2rem;
  font-size:1.2rem;
  font-weight: bold;
  line-height:2rem;
  margin-top:0;
  margin-bottom:1rem;
  border-radius: 10px;
}

.About-div{
  margin-top:0.5rem;
  text-align: center;
}

.About-section-fact{
  color:black;
  border:4px solid black;
  padding:10px;
  margin-bottom:1rem;
  text-align: center;
  font-weight:bold;
  font-size:2rem;
  display:flex;
  justify-content: center;
}

.About-sentence{
  width:100%;
  align-self: center;
  text-align: center;
}

.About-image{
  margin-top:1rem;
  margin-bottom:1rem;
}


@media screen and (max-width:800px){
  .About-section-fact{
    font-size:1.3rem;
  }
}


@media screen and (max-width:500px){
  .About-section-fact{
    font-size:1.1rem;
  }
  .About-section-fact img{
    height:30rem;
  }
}

@media screen and (max-width:400px){
  .About-paragraph{
    font-size: 1rem;
    line-height: 1.7rem;
  }
  .About-section-fact h2{
    font-size:1.4rem;
  }
  .About-section-fact img{
    height:30rem;
  }

}

@media screen and (max-width:350px){
  .About-section-fact h2{
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
  }
  .About-section-fact img{
    height:25rem;
  }
}

@media screen and (min-width:800px){
  .About-div{
    display:grid;
    grid-template-columns: 1.2fr 1.8fr;
    align-items: center;
  }
  .about-two{
    grid-gap:10px;
  }
}