:root {
  --color-main:rgb(92, 64, 255);
  --color-main-hover:rgb(73, 172, 238);
  --color-main-light: #e1f6fb;
  --color-light: #f9f1f1;
  --color-light-gray: #b2bdbd;
}

body{
  background-color: var(--color-light);;
}

.Main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top:-1rem;
  background-color: var(--color-light);
}