.Contact-main{
  color:white;
  margin-top:3rem;
  min-height:30rem;
  margin-bottom:0;
  padding-left:1rem;
  background-image: url(../media/icons/open-oy.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Contact-nest-div{
  display:grid;
  width:100%;
  color:white;
}

.Contact-social-media-title{
  margin-top:4rem;
}

.Contact-nest-div-social-media{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top:3rem;
}

.Contact-email{
  border:none;
  background-color:transparent;
  color:white;
  font-size:1rem;
  font-weight:bold;
  text-decoration: underline;
}

.Contact-email:hover{
  cursor:pointer;
  text-decoration: underline;
}

.Contact-number{
  text-decoration: underline;
  color:inherit;
  cursor: pointer;
  font-size: 1rem;
}

.Contact-social-title{
  margin-top:4rem;
  margin-bottom:0.5rem
}

.Contact-icon{
  min-width:3.5rem;
  max-width:3.5rem;
  margin-right:2rem;
}

.Contact-icon:hover{
  cursor: pointer;
}

@media screen and (max-width:500px){
  .Contact-icon{
  min-width:13%;
  max-width:13%;
  }
}

@media screen and (max-width:350px){
  .Contact-main{
    display: flex;
    flex-direction: column;
    justify-content:center;
  }
  .Contact-email{
    font-size:0.8rem;
  }
  .Contact-number{
    font-size: 0.8rem;
  }
}